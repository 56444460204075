import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './custom.scss';
import './fonts/Inter-Bold.woff2';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AcceptableUsePolicy from './routes/AcceptableUsePolicy';
import PkmnBattle, { loader as pkmnBattleLoader } from './routes/PkmnBattle';
import Demo, { loader as demoLoader } from './routes/Demo';
import Dynmap, { loader as dynmapLoader } from './routes/Dynmap';
import EssentialsX, { loader as essentialsXLoader } from './routes/EssentialsX';
import Firecracker, { loader as firecrackerLoader } from './routes/Firecracker';
import KitchenSink from './routes/KitchenSink';
import Login, { action as loginAction, loader as loginLoader } from './routes/Login';
import Permissions, { addPermissionAction, loader as permissionsLoader, removePermissionAction } from './routes/Permissions';
import PkmnLogin, { action as pkmnLoginAction } from './routes/PkmnLogin';
import PkmnMe, { loader as pkmnMeLoader } from './routes/PkmnMe';
import Players, { giveItemsAction, loader as playersLoader, setGroupAction } from './routes/Players';
import Plugins, { loader as pluginsLoader } from './routes/Plugins';
import PrivacyPolicy from './routes/PrivacyPolicy';
import ResetPassword from './routes/ResetPassword';
import Root, { loader as rootLoader } from './routes/Root';
import Analytics, { loader as analyticsLoader } from './routes/Analytics';
import Overview, { loader as overviewLoader, renameServerAction, restartAction } from './routes/Overview';
import Settings, { entitiesAction, gameSettingsAction, loader as settingsLoader, miscAction, worldGenerationAction, worldLimitsAction } from './routes/Settings';
import Signup, { action as signupAction } from './routes/Signup';
import StatjarApplication, { loader as statjarApplicationLoader } from './routes/StatjarApplication';
import StatjarDashboard, { loader as statjarDashboardLoader } from './routes/StatjarDashboard';
import StatjarHome, { loader as statjarHomeLoader } from './routes/StatjarHome';
import StatjarLogin, { action as statjarLoginAction } from './routes/StatjarLogin';
import StatjarMetric, { loader as statjarMetricLoader } from './routes/StatjarMetric';
import StatjarMetrics, { loader as statjarMetricsLoader } from './routes/StatjarMetrics';
import StatjarNamespace from './routes/StatjarNamespace';
import StatjarOnboarding, { action as statjarOnboardingAction, loader as statjarOnboardingLoader } from './routes/StatjarOnboarding';
import StatjarRoot, { loader as statjarRootLoader } from './routes/StatjarRoot';
import StatjarSignup, { action as statjarSignupAction } from './routes/StatjarSignup';
import { loader as statjarSnapshotLoader } from './routes/StatjarSnapshot';
import TermsOfService from './routes/TermsOfService';
import Whitelist, { addWhitelistAction, deleteWhitelistAction, loader as whitelistLoader } from './routes/Whitelist';
import { loader as logoutLoader } from './routes/Logout';
import { loader as pkmnLogoutLoader } from './routes/PkmnLogout';
import { loader as statjarLogoutLoader } from './routes/StatjarLogout';

const router = createBrowserRouter([
  {
    children: [
      {element: <Analytics/>, loader: analyticsLoader, path: ':serverID/analytics'},
      {
        children: [
          {action: renameServerAction, element: null, path: 'rename'},
          {action: restartAction, element: null, path: 'restart'},
        ],
        element: <Overview/>,
        loader: overviewLoader,
        path: ':serverID/overview',
      },
      {
        children: [
          {action: addPermissionAction, element: null, path: 'add-permission'},
          {action: removePermissionAction, element: null, path: 'remove-permission'},
        ],
        element: <Permissions/>,
        loader: permissionsLoader,
        path: ':serverID/permissions',
      },
      {
        children: [
          {action: giveItemsAction, element: null, path: 'give-items'},
          {action: setGroupAction, element: null, path: 'set-group'},
        ],
        element: <Players/>,
        loader: playersLoader,
        path: ':serverID/players',
      },
      {
        children: [
          {element: <Navigate to="dynmap"/>, index: true},
          {element: <Dynmap/>, loader: dynmapLoader, path: 'dynmap'},
          {element: <EssentialsX/>, loader: essentialsXLoader, path: 'essentialsx'},
        ],
        element: <Plugins/>,
        loader: pluginsLoader,
        path: ':serverID/plugins',
      },
      {
        children: [
          {action: entitiesAction, element: null, path: 'entities'},
          {action: gameSettingsAction, element: null, path: 'game-settings'},
          {action: miscAction, element: null, path: 'misc'},
          {action: worldGenerationAction, element: null, path: 'world-generation'},
          {action: worldLimitsAction, element: null, path: 'world-limits'},
        ],
        element: <Settings/>,
        loader: settingsLoader,
        path: ':serverID/settings',
      },
      {
        children: [
          {action: addWhitelistAction, element: null, path: 'add'},
          {action: deleteWhitelistAction, element: null, path: 'delete'},
        ],
        element: <Whitelist/>,
        loader: whitelistLoader,
        path: ':serverID/whitelist'
      },
      {loader: logoutLoader, path: 'logout'},
    ],
    element: <Root/>,
    loader: rootLoader,
    path: '/',
  },
  {element: <AcceptableUsePolicy/>, path: '/acceptable-use-policy'},
  {element: <Demo/>, loader: demoLoader, path: '/demo'},
  {element: <Firecracker/>, loader: firecrackerLoader, path: '/firecracker'},
  {element: <KitchenSink/>, path: '/kitchen-sink'},
  {action: loginAction, element: <Login/>, loader: loginLoader, path: '/login'},
  {element: <PrivacyPolicy/>, path: '/privacy-policy'},
  {element: <ResetPassword/>, loader: loginLoader, path: '/reset-password'},
  {action: signupAction, element: <Signup/>, loader: loginLoader, path: '/signup/:onboardingToken'},
  {element: <TermsOfService/>, path: '/terms-of-service'},

  {element: <PkmnBattle/>, loader: pkmnBattleLoader, path: '/pkmn/battles/:battleID'},
  {action: pkmnLoginAction, element: <PkmnLogin/>, path: '/pkmn/login'},
  {loader: pkmnLogoutLoader, path: '/pkmn/logout'},
  {element: <PkmnMe/>, loader: pkmnMeLoader, path: '/pkmn/me'},

  {
    children: [
      {element: <StatjarHome/>, loader: statjarHomeLoader, path: 'home'},
      {element: <StatjarNamespace/>, path: 'namespaces/:namespaceID'},
      {loader: statjarLogoutLoader, path: 'logout'},
    ],
    element: <StatjarRoot/>,
    loader: statjarRootLoader,
    path: '/statjar',
  },

  {element: <StatjarApplication/>, loader: statjarApplicationLoader, path: '/statjar/applications/:applicationUUID'},
  {element: <StatjarDashboard/>, loader: statjarDashboardLoader, path: '/statjar/dashboards'},
  {action: statjarLoginAction, element: <StatjarLogin/>, path: '/statjar/login'},
  {element: <StatjarMetrics/>, loader: statjarMetricsLoader, path: '/statjar/metrics'},
  {element: <StatjarMetric/>, loader: statjarMetricLoader, path: '/statjar/metrics/:metricUUID'},
  {action: statjarOnboardingAction, element: <StatjarOnboarding/>, loader: statjarOnboardingLoader, path: '/statjar/signup/:activationToken'},
  {action: statjarSignupAction, element: <StatjarSignup/>, path: '/statjar/signup'},
  {element: <StatjarApplication/>, loader: statjarSnapshotLoader, path: '/statjar/snapshots/:snapshotUUID'},
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
