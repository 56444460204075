import React from 'react';
import { useLoaderData } from 'react-router-dom';
import LandingFooter from '../components/LandingFooter';
import LandingHeader from '../components/LandingHeader';

interface MicroVM {
  id: number;
  boot_args: string;
  ipv4_address: string;
  kernel_image_path: string;
  mac_address: string;
  root_drive_path: string;
}

function Firecracker() {
  const loaderData = useLoaderData() as MicroVM[];

  return (
    <div className="container pt-3" style={{maxWidth: '960px'}}>
      <LandingHeader/>
      <section>
        <h1>Firecracker</h1>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Boot args</th>
              <th scope="col">IPv4 address</th>
              <th scope="col">MAC address</th>
              <th scope="col">Root drive path</th>
            </tr>
          </thead>
          <tbody>
            {loaderData.map(microVM => (
              <tr key={microVM.id}>
                <th scope="row">{microVM.id}</th>
                <td>{microVM.boot_args}</td>
                <td><code>{microVM.ipv4_address}</code></td>
                <td><code>{microVM.mac_address}</code></td>
                <td>{lastSegment(microVM.root_drive_path)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <LandingFooter/>
    </div>
  );
}

function lastSegment(path: string): string {
  const parts = path.split('/');
  return parts[parts.length - 1];
}

export async function loader(): Promise<Response | null> {
  const url = 'https://2b88426.massivedynamic.io:8081/firecracker/microvms';
  const resp = await fetch(url);

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default Firecracker;
