import clsx from 'clsx';
import React from 'react';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { Pkmn } from '../routes/PkmnBattle';

interface Props {
  activePkmnID: number;
  battleID: number;
  pkmn: Pkmn[];
  sendJSONMessage: SendJsonMessage;
  userIdx: number;
}

function PkmnList({activePkmnID, battleID, pkmn, sendJSONMessage, userIdx}: Props) {
  const change = (pkmnID: number) => {
    sendJSONMessage({
      battleID: battleID,
      pkmnID: pkmnID,
      type: 'change',
      userIdx: userIdx,
    });
  };

  return (
    <div className="list-group">
      {pkmn.map(pkmn => <button
        className={`${pkmn.id === activePkmnID ? 'fw-medium text-bg-light' : ''} align-items-center d-flex list-group-item list-group-item-action px-2`}
        disabled={pkmn.hp === 0}
        key={pkmn.id}
        onClick={_ => change(pkmn.id)}
        type="button">
        <img alt={pkmn.species} className="me-1" src={`/pkmn/${pkmn.species}.png`}
             style={{height: '32px', width: '32px'}}/>
        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between">
            <span className={clsx({'text-decoration-line-through': pkmn.hp === 0})}>{pkmn.species}</span>
            <small>Level {pkmn.level}</small>
          </div>
          {pkmn.id === activePkmnID && (
            <>
              <div className="progress"
                   role="progressbar"
                   aria-valuenow={pkmn.hp}
                   aria-valuemin={0}
                   aria-valuemax={pkmn.hpTotal}
                   style={{height: '10px'}}>
                <div className="bg-success progress-bar"
                     style={{width: `${pkmn.hp / pkmn.hpTotal * 100}%`}}></div>
              </div>
              <div className="d-flex justify-content-end">
                <small>{pkmn.hp}/{pkmn.hpTotal} PS</small>
              </div>
            </>
          )}
        </div>
      </button>)}
    </div>
  );
}

export default PkmnList;
