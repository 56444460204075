import React from 'react';
import { json, useLoaderData } from 'react-router-dom';
import { Dashboard } from '../types/Statjar';

export default function StatjarDashboard(): JSX.Element {
  const dashboard = useLoaderData() as Dashboard;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-6">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">user ID</th>
              <th scope="col">name</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">xxx</th>
                <td>xxx</td>
                <td>{dashboard.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export async function loader() {
  return json({
    name: 'Something',
  });
}
