import React from 'react';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { user1AttackOrChangeOrSkip } from '../routes/PkmnBattle';

interface Props {
  battleID: number;
  locationPkmn1: number;
  locationPkmn2: number;
  move1: string;
  move2: string;
  move3: string;
  move4: string;
  nextAction: string;
  sendJSONMessage: SendJsonMessage;
  setHoveredMove: (hoveredMove: string) => void;
  userIdx: number;
}

function MoveSelector({battleID, locationPkmn1, locationPkmn2, move1, move2, move3, move4, nextAction, sendJSONMessage, setHoveredMove, userIdx}: Props) {
  const attack = (move: string) => {
    sendJSONMessage({
      battleID: battleID,
      move: move,
      type: 'attack',
      userIdx: userIdx,
    });
  };

  return (
    <>
      <div className="list-group list-group-horizontal" style={{width: '375px'}}>
        <button className="list-group-item list-group-item-action text-center w-50"
                disabled={move1 === '' || nextAction !== user1AttackOrChangeOrSkip}
                onClick={_ => attack(move1)}
                onMouseEnter={() => setHoveredMove(move1)}
                onMouseLeave={() => setHoveredMove('')}
                style={{borderBottomLeftRadius: 0}}
                type="button">
          {move1 !== '' ? move1 : 'N/A'}
        </button>
        <button className="list-group-item list-group-item-action text-center w-50"
                disabled={move2 === '' || nextAction !== user1AttackOrChangeOrSkip}
                onClick={_ => attack(move2)}
                onMouseEnter={() => setHoveredMove(move2)}
                onMouseLeave={() => setHoveredMove('')}
                style={{borderBottomRightRadius: 0}}
                type="button">
          {move2 !== '' ? move2 : 'N/A'}
        </button>
      </div>
      <div className="disabled list-group list-group-horizontal mb-1" style={{width: '375px'}}>
        <button className="list-group-item list-group-item-action text-center w-50"
                disabled={move3 === '' || nextAction !== user1AttackOrChangeOrSkip}
                onClick={_ => attack(move3)}
                onMouseEnter={() => setHoveredMove(move3)}
                onMouseLeave={() => setHoveredMove('')}
                style={{borderTopLeftRadius: 0, borderTopWidth: 0}}
                type="button">
          {move3 !== '' ? move3 : 'N/A'}
        </button>
        <button className="list-group-item list-group-item-action text-center w-50"
                disabled={move4 === '' || nextAction !== user1AttackOrChangeOrSkip}
                onClick={_ => attack(move4)}
                onMouseEnter={() => setHoveredMove(move4)}
                onMouseLeave={() => setHoveredMove('')}
                style={{borderTopRightRadius: 0, borderTopWidth: 0}}
                type="button">
          {move4 !== '' ? move4 : 'N/A'}
        </button>
      </div>
    </>
  );
}

export default MoveSelector;
