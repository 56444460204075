import React from 'react';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { Move, Pkmn, user1AttackOrChangeOrSkip, user1ChangeOrMoveOrSkip } from '../routes/PkmnBattle';

interface Props {
  activePkmn1ID: number;
  activePkmn2ID: number;
  battleID: number;
  hoveredMove: string;
  locationPkmn1: number;
  locationPkmn2: number;
  moves: Move[];
  nextAction: string;
  pkmn: Pkmn[];
  sendJSONMessage: SendJsonMessage;
}

const imgStyle: React.CSSProperties = {
  height: '50px',
  objectFit: 'contain',
  width: '50px',
};

const tdStyleBase: React.CSSProperties = {
  border: '1px solid #d0d0d0',
  height: '75px',
  textAlign: 'center',
  width: '75px',
};

function tdStyle(id: number,
                 locationPkmn1: number,
                 locationPkmn2: number,
                 nextAction: string,
                 range: number[][] | undefined): React.CSSProperties {
  if (nextAction === user1ChangeOrMoveOrSkip && validMoves[locationPkmn1].includes(id) && id !== locationPkmn2) {
    return {
      ...tdStyleBase,
      backgroundColor: 'rgba(0, 0, 0, .05)',
      cursor: 'pointer',
    };
  }

  if (nextAction === user1AttackOrChangeOrSkip && range !== undefined && range[locationPkmn1].includes(id)) {
    return {
      ...tdStyleBase,
      backgroundColor: 'rgba(0, 0, 0, .05)',
    };
  }

  return tdStyleBase;
}

const validMoves: number[][] = [
  [1, 5],
  [0, 2, 6],
  [1, 3, 7],
  [2, 4, 8],
  [3, 9],
  [0, 6, 10],
  [1, 5, 7, 11],
  [2, 6, 8, 12],
  [3, 7, 9, 13],
  [4, 8, 14],
  [5, 11, 15],
  [6, 10, 12, 16],
  [7, 11, 13, 17],
  [8, 12, 14, 18],
  [9, 13, 19],
  [10, 16, 20],
  [11, 15, 17, 21],
  [12, 16, 18, 22],
  [13, 17, 19, 23],
  [14, 18, 24],
  [15, 21],
  [16, 20, 22],
  [17, 21, 23],
  [18, 22, 24],
  [19, 23],
];

function Board({activePkmn1ID, activePkmn2ID, battleID, hoveredMove, locationPkmn1, locationPkmn2, moves, nextAction, pkmn, sendJSONMessage}: Props) {
  const pkmn1 = pkmn.find(pkmn => pkmn.id === activePkmn1ID)!;
  const pkmn2 = pkmn.find(pkmn => pkmn.id === activePkmn2ID)!;

  const range = moves.find(moves => moves.name === hoveredMove)?.range;

  const move = (src: number, dst: number) => {
    console.log(`${src} -> ${dst}`);
    if (validMoves[src].includes(dst) && dst !== locationPkmn2) {
      sendJSONMessage({
        battleID: battleID,
        dst: dst,
        src: src,
        type: 'move',
        userIdx: 1,
      });
    }
  };

  return (
    <table className="mb-5">
      <tbody>
        <tr>
          {[20, 21, 22, 23, 24].map(x => <td id={`${x}`} key={x} onClick={_ => move(locationPkmn1, x)} style={tdStyle(x, locationPkmn1, locationPkmn2, nextAction, range)}>
            {x === locationPkmn1 && <img alt={pkmn1.species} src={`/pkmn/${pkmn1.species}.gif`} style={imgStyle}/>}
            {x === locationPkmn2 && <img alt={pkmn2.species} src={`/pkmn/${pkmn2.species}.gif`} style={imgStyle}/>}
          </td>)}
        </tr>
        <tr>
          {[15, 16, 17, 18, 19].map(x => <td id={`${x}`} key={x} onClick={_ => move(locationPkmn1, x)} style={tdStyle(x, locationPkmn1, locationPkmn2, nextAction, range)}>
            {x === locationPkmn1 && <img alt={pkmn1.species} src={`/pkmn/${pkmn1.species}.gif`} style={imgStyle}/>}
            {x === locationPkmn2 && <img alt={pkmn2.species} src={`/pkmn/${pkmn2.species}.gif`} style={imgStyle}/>}
          </td>)}
        </tr>
        <tr>
          {[10, 11, 12, 13, 14].map(x => <td id={`${x}`} key={x} onClick={_ => move(locationPkmn1, x)} style={tdStyle(x, locationPkmn1, locationPkmn2, nextAction, range)}>
            {x === locationPkmn1 && <img alt={pkmn1.species} src={`/pkmn/${pkmn1.species}.gif`} style={imgStyle}/>}
            {x === locationPkmn2 && <img alt={pkmn2.species} src={`/pkmn/${pkmn2.species}.gif`} style={imgStyle}/>}
          </td>)}
        </tr>
        <tr>
          {[5, 6, 7, 8, 9].map(x => <td id={`${x}`} key={x} onClick={_ => move(locationPkmn1, x)} style={tdStyle(x, locationPkmn1, locationPkmn2, nextAction, range)}>
            {x === locationPkmn1 && <img alt={pkmn1.species} src={`/pkmn/${pkmn1.species}.gif`} style={imgStyle}/>}
            {x === locationPkmn2 && <img alt={pkmn2.species} src={`/pkmn/${pkmn2.species}.gif`} style={imgStyle}/>}
          </td>)}
        </tr>
        <tr>
          {[0, 1, 2, 3, 4].map(x => <td id={`${x}`} key={x} onClick={_ => move(locationPkmn1, x)} style={tdStyle(x, locationPkmn1, locationPkmn2, nextAction, range)}>
            {x === locationPkmn1 && <img alt={pkmn1.species} src={`/pkmn/${pkmn1.species}.gif`} style={imgStyle}/>}
            {x === locationPkmn2 && <img alt={pkmn2.species} src={`/pkmn/${pkmn2.species}.gif`} style={imgStyle}/>}
          </td>)}
        </tr>
      </tbody>
    </table>
  );
}

export default Board;
