import React, { useState } from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import Board from '../components/Board';
import MoveSelector from '../components/MoveSelector';
import PkmnList from '../components/PkmnList';
import { ReactComponent as ArrowRight } from '../images/arrow-right.svg';

export const user1AttackOrChangeOrSkip = 'USER1_ATTACK_OR_CHANGE_OR_SKIP';
export const user1Change = 'USER1_CHANGE';
export const user1ChangeOrMoveOrSkip = 'USER1_CHANGE_OR_MOVE_OR_SKIP';
export const user1KO = 'USER1_KO';

export const user2AttackOrChangeOrSkip = 'USER2_ATTACK_OR_CHANGE_OR_SKIP';
export const user2Change = 'USER2_CHANGE';
export const user2ChangeOrMoveOrSkip = 'USER2_CHANGE_OR_MOVE_OR_SKIP';
export const user2KO = 'USER2_KO';

export interface Move {
  name: string;
  range: number[][];
}

interface LoaderData {
  moves: Move[];
}

interface WebSocketData {
  activePkmn1: number;
  activePkmn2: number;
  id: number;
  locationPkmn1: number;
  locationPkmn2: number;
  nextAction: string;
  user1: User;
  user2: User;
}

export interface Pkmn {
  hp: number;
  hpTotal: number;
  id: number;
  level: number;
  move1: string;
  move2: string;
  move3: string;
  move4: string;
  species: string;
}

interface User {
  pkmn: Pkmn[];
  username: string;
}

function webSocketURL(): string {
  const token = localStorage.getItem('pkmn_token');

  if (window.location.hostname === 'localhost') {
    return `ws://localhost:8080/pokemon/ws?token=${token}`;
  }

  return `wss://api.varokegaming.com/pokemon/ws?token=${token}`;
}

function PkmnBattle() {
  const {moves} = useLoaderData() as LoaderData;
  const [hoveredMove, setHoveredMove] = useState('');
  const {lastJsonMessage, sendJsonMessage} = useWebSocket(webSocketURL(), {
    onOpen: () => console.log('opened'),
    shouldReconnect: (closeEvent) => true,
  });

  if (!lastJsonMessage) {
    return (
      <p>Loading...</p>
    );
  }

  const {
    activePkmn1: activePkmn1ID,
    activePkmn2: activePkmn2ID,
    id,
    locationPkmn1,
    locationPkmn2,
    nextAction,
    user1,
    user2
  } = lastJsonMessage as WebSocketData;

  const skip = () => {
    sendJsonMessage({
      battleID: id,
      type: 'skip',
      userIdx: 1,
    });
  };

  const activePkmn1 = user1.pkmn.find(pkmn => pkmn.id === activePkmn1ID)!;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col mb-4">
          {nextAction === user1AttackOrChangeOrSkip && (
            <div className="alert alert-primary">It's your turn to attack, change your Pokémon or skip.</div>
          )}
          {nextAction === user1Change && (
            <div className="alert alert-primary">Your Pokémon fainted, choose another one to continue the battle.</div>
          )}
          {nextAction === user1ChangeOrMoveOrSkip && (
            <div className="alert alert-primary">It's your turn to move or change your Pokémon, but you can also skip.</div>
          )}
          {nextAction === user1KO && (
            <div className="alert alert-primary">@{user1.username} blacked out!</div>
          )}
          {nextAction === user2KO && (
            <div className="alert alert-primary">@{user2.username} is out of usable Pokémon!</div>
          )}
          {(nextAction === user2AttackOrChangeOrSkip || nextAction === user2Change || nextAction === user2ChangeOrMoveOrSkip) && (
            <div className="alert alert-primary">Stand by... Waiting on opponent.</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 mb-4">
          <PkmnList activePkmnID={activePkmn1ID}
                    battleID={id}
                    pkmn={user1.pkmn}
                    sendJSONMessage={sendJsonMessage}
                    userIdx={1}/>
        </div>
        <div className="align-items-center col-lg-6 d-flex flex-column mb-5">
          <Board activePkmn1ID={activePkmn1ID}
                 activePkmn2ID={activePkmn2ID}
                 battleID={id}
                 hoveredMove={hoveredMove}
                 locationPkmn1={locationPkmn1}
                 locationPkmn2={locationPkmn2}
                 moves={moves}
                 nextAction={nextAction}
                 pkmn={[...user1.pkmn, ...user2.pkmn]}
                 sendJSONMessage={sendJsonMessage}/>
          <MoveSelector battleID={id}
                        locationPkmn1={locationPkmn1}
                        locationPkmn2={locationPkmn2}
                        move1={activePkmn1.move1}
                        move2={activePkmn1.move2}
                        move3={activePkmn1.move3}
                        move4={activePkmn1.move4}
                        nextAction={nextAction}
                        sendJSONMessage={sendJsonMessage}
                        setHoveredMove={setHoveredMove}
                        userIdx={1}/>
          <button className="btn btn-link icon-link icon-link-hover"
                  disabled={nextAction !== user1AttackOrChangeOrSkip && nextAction !== user1ChangeOrMoveOrSkip}
                  onClick={_ => skip()}
                  type="button">skip <ArrowRight/></button>
        </div>
        <div className="col-lg-3 mb-4">
          <PkmnList activePkmnID={activePkmn2ID}
                    battleID={id}
                    pkmn={user2.pkmn}
                    sendJSONMessage={sendJsonMessage}
                    userIdx={2}/>
        </div>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function loader({ params }: any): Promise<any> {
  const token = localStorage.getItem('pkmn_token');
  if (!token) {
    return redirect('/pkmn/login');
  }

  const url = `${apiBaseURL}/pokemon/battles/${params.battleID}/metadata`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('pkmn_token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default PkmnBattle;
