import React from 'react';
import { Link, redirect, useLoaderData } from 'react-router-dom';

function PkmnMe() {
  const loaderData = useLoaderData() as any;

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col">
          <div className="list-group">
            {loaderData.map((battleID: number) => (
              <Link className="list-group-item list-group-item-action" key={battleID} to={`/pkmn/battles/${battleID}`}>
                {`Battle #${battleID}`}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function loader(): Promise<any> {
  const token = localStorage.getItem('pkmn_token');
  if (!token) {
    return redirect('/pkmn/login');
  }

  const url = `${apiBaseURL}/pokemon/me`;
  const resp = await fetch(url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('pkmn_token')}`,
    },
  });

  if (resp.status !== 200) {
    throw new Response(resp.statusText, {status: resp.status});
  }

  return await resp.json();
}

export default PkmnMe;
