import { useState } from 'react';
import { Form, Link, redirect, useActionData } from 'react-router-dom';

export default function PkmnLogin(): JSX.Element {
  const error = useActionData() as Error;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="container mt-5" style={{maxWidth: '500px'}}>
      {error && <div className="alert alert-danger" role="alert">{error.message}</div>}
      <Form method="post">
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email address
          </label>
          <input className="form-control" id="email" name="email" onChange={e => setEmail(e.target.value)} type="email"
                 value={email}/>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input className="form-control" id="password" name="password" onChange={e => setPassword(e.target.value)}
                 type="password" value={password}/>
        </div>
        <button className="btn btn-primary mb-3" disabled={email === '' || password === ''} type="submit">Submit
        </button>
      </Form>
      <Link to="/statjar/signup">Need an account?</Link>
    </div>
  );
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function action({request}: any): Promise<Error | Response> {
  const formData = await request.formData();
  const data = {
    email: formData.get('email'),
    password: formData.get('password'),
  };

  console.log(data);

  if (typeof data.email !== 'string' || !data.email.includes('@')) {
    return new Error('That doesn\'t look like an email address');
  }

  const url = `${apiBaseURL}/pokemon/login`;
  try {
    const resp = await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
    });

    if (resp.status === 400) {
      return new Error('That doesn\'t look right');
    }

    if (resp.status === 401) {
      return new Error('Those credentials are not valid');
    }

    if (!resp.ok) {
      return new Error('It was not possible to handle your request');
    }

    const jsonResp = await resp.json();
    localStorage.setItem('pkmn_token', jsonResp.token);

    return redirect('/pkmn/me');
  } catch (error) {
    return new Error('It was not possible to submit your request');
  }
}
